import React, { useState, useEffect } from 'react';

import Main from '../components/Main';
import OverlayImagesComponent_DB from '../components/AvatarMakerPage/OverlayImagesComponent_DB'; 

import KPI_OVERLAY    from '../assets/img/avatarmaker120/120years_template_v3.png';
import KPI_OVERLAY_2  from '../assets/img/avatarmaker120/120years_template_v4.png';
import KPI_GRAY_PEOPLE from '../assets/img/tamplate-overlay.png';

const data = {
    id: 0,
    titele: "Новий Рік 2025",
    year: "24-25",
    arr: [
        {
            eng: 'https://7fc65ea596c38701.cdn.express/secure/IrMjLYPX4AI4Z8_llV__lg/clubsitecreator/brendcenter/120years/120years_template_v4.png',
            ukr: 'https://7fc65ea596c38701.cdn.express/secure/SNrIzut4vTlCXVT-Exmq6w/clubsitecreator/brendcenter/120years/120years_template_v4_ukr.png',
        },
    ]
}

const AvatarMakerPage120 = () => {
    return (
        <>
        <Main>
            <OverlayImagesComponent_DB 
                data={data}
                img={KPI_OVERLAY} 
                img2={KPI_OVERLAY_2} 
                imgGrayPepole={KPI_GRAY_PEOPLE}
                defSize='large'
                cloudFolder="NewYear25"
                needCircle={true} />
        </Main>
        </>
    )
}

export default AvatarMakerPage120;