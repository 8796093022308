import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

import LanguageContextProvider from './modules/LanguageProvider/LanguageContextProvider';
import CertificateMaker_bot from './pages/CertificateMaker_bot';
import HomePage from './pages/HomePage';
import VideoDubbing from './pages/VideoDubbing';
import AvatarMakerPage9 from './pages/AvatarMakerPage9';
import AvatarMakerPage11 from './pages/AvatarMakerPage11';
import AvatarMakerPage12 from './pages/AvatarMakerPage12';
import AvatarMakerPage120 from './pages/AvatarMakerPage120';
import SpreadsheetReader from './modules/GoogleSpreadsheet/SpreadsheetReader';

import CertificateMakerPage from './pages/CertificateMakerPage';
import CertificateMakerPageGoogle from './pages/CertificateMakerPageGoogle';

const App = () => {
  return (
    <LanguageContextProvider>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/certificatemaker" element={<CertificateMakerPage />} />
        
        <Route path="/bot_rotary_basic" element={<CertificateMaker_bot />} />
        <Route path="/video_dubbing" element={<VideoDubbing />} />

        <Route path="/avatarmaker10" element={<AvatarMakerPage9 />} />
        <Route path="/avatarmaker11" element={<AvatarMakerPage11 />} />
        <Route path="/avatarmaker12" element={<AvatarMakerPage12 />} />
        <Route path="/avatarmaker120" element={<AvatarMakerPage120 />} />
        {/* <Route path="/clublogomaker" element={<LogoMakerPage />} /> */}
        
        
        <Route path="/googletable" element={<SpreadsheetReader />} />
        <Route path="/certificatemaker_google" element={<CertificateMakerPageGoogle />} />
        
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
    </LanguageContextProvider>
  );
};

export default App;
